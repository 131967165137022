<template>
  <vue-flux
    :options="vfOptions"
    :images="vfImages"
    :transitions="vfTransitions"
    :captions="vfCaptions"
    ref="slider"
  >
    <template v-slot:caption>
      <flux-caption v-slot="captionProps" class="overlay-bg3 fontStandard transitionCubicBezier_1s">
        <h5 class="pt-1">
          <strong>{{ captionProps.text }}</strong>
        </h5>
      </flux-caption>
    </template>
  </vue-flux>
</template>

<script>
import { mapState } from "vuex";
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,
} from "vue-flux";

export default {
  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
  },
  computed: {
    ...mapState(["events"]),
    vfImages() {
      if (this.events.width > 1200) {
        this.vfCaptions = [
          "Port de Nice",
          "Nice de l'observatoire",
          "Le jardin Albert 1er",
          "Place Garibaldi",
          "Vue du chateau de Nice",
          "Le vieux Nice / vue du chateau de Nice",
          "Cascade du chateau de Nice",
          "I Love NICE",
          "Musée des arts asiatiques / Parc Phenix",
          "Iron Man Nice",
          "Le port de Nice",
          "Le cours Saleya Nice",
          "Chemin de fer de Provence / Nice - Digne",
        ];
        return [
          require("@/assets/webp/nice/1.webp"),
          require("@/assets/webp/nice/2.webp"),
          require("@/assets/webp/nice/3.webp"),
          require("@/assets/webp/nice/4.webp"),
          require("@/assets/webp/nice/5.webp"),
          require("@/assets/webp/nice/6.webp"),
          require("@/assets/webp/nice/7.webp"),
          require("@/assets/webp/nice/8.webp"),
          require("@/assets/webp/nice/9.webp"),
          require("@/assets/webp/nice/10.webp"),
          require("@/assets/webp/nice/11.webp"),
          require("@/assets/webp/nice/12.webp"),
          require("@/assets/webp/nice/13.webp"),
        ];
      } else {
        this.vfCaptions = [];
        return [];
      }
    },
  },
  data: () => ({
    vfOptions: {
      allowFullscreen: false,
      allowToSkipTransition: false,
      autohideTime: 2500,
      autoplay: true,
      bindKeys: false,
      delay: 6000,
      enableGestures: false,
      infinite: true,
      lazyLoad: true,
      lazyLoadAfter: 3,
    },
    vfTransitions: ["blocks1", "blinds2d", "blocks2"],
    vfCaptions: [],
  }),
};
</script>

<style scoped>
.overlay-bg3 {
  background: #163a58aa;
}
</style>
